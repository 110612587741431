/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import "jquery"
import "bootstrap/dist/js/bootstrap"
import Turbolinks from "turbolinks"
import GLightbox from "glightbox"
import "pickadate/lib/picker"
import "pickadate/lib/picker.date"

import "@tabler/icons-webfont/tabler-icons.css"
import "pickadate/lib/themes/default.css"
import "pickadate/lib/themes/default.date.css"

require("@rails/ujs").start()
Turbolinks.start()

document.addEventListener("turbolinks:before-cache", () => {
  $("[data-toggle='tooltip']").tooltip("hide");
})

document.addEventListener("turbolinks:load", () => {
  $("[data-toggle='tooltip']").tooltip({
    trigger: "hover",
    placement: "bottom"
  })

  new GLightbox()
})

import "catalog/controllers"
